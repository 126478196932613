'use strict';

/* exported
  monitoringCtrl
 */
function monitoringCtrl($rootScope, $scope, $location, $sce, Restangular, $uibModal, MonitoringService, moment) {
  $scope.biDashboardService = MonitoringService;
  $scope.queryItem = {};
  $scope.disableSave = false;
  $scope.events = [];
  $scope.filteredEvents = [];
  $scope.currEventsToShow = [];
  $scope.isLoading = false;
  $scope.eventHoverData = '';

  $scope.fetchEvents = function() {
    if (!$scope.biDashboardService.filters.visitorId || $scope.biDashboardService.filters.visitorId.length < 25) {
      return $rootScope.$emit('notify', {type: 'error', title: 'Error', message: 'Invalid visitorId.'});
    }
    var fromMoment = moment($scope.biDashboardService.filters.fromDate);
    var toMoment = moment($scope.biDashboardService.filters.toDate);
    if (fromMoment.isAfter(toMoment)) {
      return $rootScope.$emit('notify', {type: 'error', title: 'Error', message: 'From-date cannot be after To-date'});
    }
    var diff = toMoment.diff(fromMoment, 'days');
    if (diff > 3) {
      return $rootScope.$emit('notify', {type: 'error', title: 'Error', message: 'Please select time span which is less than 4 days.'});
    }
    $scope.events = [];
    try {
      $scope.isLoading = true;
      const url = 'monitoring/' + $scope.biDashboardService.filters.visitorId + '/events';
      var queryString = {
        fromDate: fromMoment.format('YYYY-MM-DD'),
        toDate: toMoment.format('YYYY-MM-DD'),
      };
  
      return Restangular.all(url).getList(queryString).then(function(events) {
        $scope.isLoading = false;
        $scope.events = events;
        $scope.filteredEvents = events;
        $scope.eventsPageChanged();
      });
    } catch (err) {
      $scope.isLoading = false;
    } finally {
      $scope.isLoading = false;
    }
  }

  $scope.init = (function() {
    // Check if query parameter 'visitorId' exists
    var visitorId = $location.search().visitorId;
    var hasVisitorId = visitorId && visitorId.length > 0;
    if (hasVisitorId) {
      $scope.biDashboardService.filters.visitorId = visitorId;
      $scope.fetchEvents();
    }
  })();

  $scope.setActiveTab = function(index) {
    $scope.activeTab = index;
    $scope.biDashboardService.activeTab = index;
  };


  $scope.$watch('biDashboardService.filters.visitorId', function (visitorId, previousValue) {

    if (visitorId === previousValue) {
      return;
    }
    $rootScope.$broadcast('filters.visitorId', visitorId);
    var search = $location.search();
    search.visitorId = visitorId;
    $location.search(search);
  });

  $scope.setHover = _.debounce(function(event, value, data, isExperiments) {
    $scope.isHovered = value;
    if (value) {
      var eventHoverData = '';
      var tooltipWidth = 400;
      var tooltipHeight = 700;
      if (isExperiments) {
        var experiments = data.find(function(d) { return d.key === 'experiments' });
        if (experiments && experiments.value.length > 0) {
          var expArr = experiments.value.split(',');
          for (var i = 0; i< expArr.length; i++) {
            var d = expArr[i].split('-');
            if (d.length > 1) {
              eventHoverData += '<b>' + d[0] + ':</b> ' + d[1] + '</br>';
            }
          }
        }
      } else {
        for (var i = 0; i< data.length; i++) {
          if (data[i].key !== 'experiments') {
            eventHoverData += '<b>' + data[i].key + ':</b> ' + data[i].value + '</br>';
          }
        }
      }

      $scope.eventHoverData = $sce.trustAsHtml(eventHoverData);
      $scope.tooltipStyle = {
        top: (window.innerHeight - tooltipHeight) / 2 + window.scrollY + 'px',
        left: (window.innerWidth - tooltipWidth) / 2 + window.scrollX + 'px',
        maxWidth: '400px',
        maxHeight:'500px',
        overflowY: 'scroll',
        zIndex: 5000,
      };
    } else {
      $scope.eventHoverData = '';
    }
  }, 100);

  $scope.extractEventProperty = function (event, propName) {
    var prop = event.properties.find(function(p) { return p.key === propName });
    if (!prop) {
      return '';
    }
    return prop.value;
  }

  $scope.$watch('biDashboardService.filters.fromDate', function (fromDate, previousValue) {

    var d = moment(fromDate).format('YYYY-MM-DD');
    if (d === previousValue) {
      return;
    }
    $rootScope.$broadcast('filters.fromDate', d);
    var search = $location.search();
    search.fromDate = d;
    $location.search(search);
  });
  $scope.$watch('biDashboardService.filters.toDate', function (toDate, previousValue) {

    const d = moment(toDate).format('YYYY-MM-DD');
    if (d === previousValue) {
      return;
    }
    $rootScope.$broadcast('filters.toDate', d);
    var search = $location.search();
    search.toDate = d;
    $location.search(search);
  });

  $scope.convertISO8601ToHHMM = function(isoTime) {
    const match = isoTime.match(/P(\d+D)?T?(\d+H)?(\d+M)?/);
    if (!match) {
      return isoTime;
    }

    const daysStr = match[1];
    const hoursStr = match[2];
    const minutesStr = match[3];

    const days = daysStr ? parseInt(daysStr.replace('D', '')) : 0;
    const hours = hoursStr ? parseInt(hoursStr.replace('H', '')) : 0;
    const minutes = minutesStr ? parseInt(minutesStr.replace('M', '')) : 0;

    const totalHours = (days * 24) + hours;

    return `${String(totalHours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  };

  $scope.eventsPageChanged = function () {
    const page = $scope.biDashboardService.currentEventsPage;
    const startIndex = ((page - 1) * $scope.biDashboardService.filters.eventsPageSize);
    let endIndex = startIndex + $scope.biDashboardService.filters.eventsPageSize;
    var filters = $scope.biDashboardService.filters.eventNames;
    $scope.filteredEvents = $scope.events.filter(function(event) {
      if (event.name.indexOf('web_vital') > -1) {
        return filters['web_vitals'];
      }
      return filters[event.name] !== 'undefined' ? filters[event.name] : true;
    });
    if (endIndex > $scope.filteredEvents.length) {
      endIndex = $scope.filteredEvents.length;
    }

    $scope.currEventsToShow = $scope.filteredEvents.slice(startIndex, endIndex);
  }

  $scope.openEventsFiltersModal = function() {
    $uibModal.open({
      templateUrl: 'views/monitoring/events-filters.html',
      controller: 'EventsFiltersCtrl',
      resolve: {
        eventNames: [function(){ return $scope.biDashboardService.filters.eventNames }],
      },
    })
    .result
    .then(function(filters) {
      if(filters) {
        $scope.biDashboardService.filters.eventNames = filters;
        $scope.currEventsToShow = $scope.currEventsToShow.filter(function(event) {
          if (event.name.indexOf('web_vital') > -1) {
            return filters['web_vitals'];
          }
          return filters[event.name];
        });
      }
    });
  }
}

'use strict';
function menuMonitoringCtrl($rootScope, $scope, $location, user, monitoringService) {
  $scope.monitoringService = monitoringService;
  $scope.user = user;
  $scope.showEventsTab = user.scope.includes('view-user-events');
  var queryParams = $location.search();
  monitoringService.activeTab = 0;
  if (queryParams.visitorId) {
    monitoringService.filters.visitorId = queryParams.visitorId;
  }
  if (queryParams.fromDate) {
    monitoringService.filters.fromDate = new Date(queryParams.fromDate);
  }
  if (queryParams.toDate) {
    monitoringService.filters.toDate = new Date(queryParams.toDate);
  }
  
  monitoringService.setActiveTab = function (index) {
    monitoringService.activeTab = index;
    $rootScope.$broadcast('filters.page', name);
    var search = $location.search();
    search.page = name;
    $location.search(search)
  };
}
'use strict';

/* exported
eventsFiltersCtrl
 */

function eventsFiltersCtrl($scope, $uibModalInstance, eventNames) {
  $scope.eventNames = eventNames;
  $scope.keys = Object.keys(eventNames);
  $scope.setValue = function (name, value) {
    $scope.eventNames[name] = value;
  }
  $scope.clearOrSelectAll = function(op) {
    Object.keys($scope.eventNames).forEach(function(key) {
      $scope.eventNames[key] = op === 'clear' ? false : true;
    })
  }
  $scope.apply = function() {
    $uibModalInstance.close($scope.eventNames);
  };

  $scope.cancel = function () {
    $uibModalInstance.close(false);
  };
}

'use strict';

/* exported
 monitoringProvider
 */

function monitoringProvider() {
  this.$get = ['$rootScope', '$location', '$uibModal', 'moment', function () {
    var biDashboardService = {
      activeTab: 0,
      currentPage: 1,
      biQueries: [],
      currentEventsPage: 1,
      filters: {
        visitorId: undefined,
        fromDate: moment().subtract(1, 'days').toDate(),
        toDate: moment().toDate(),
        eventsPageSize: 10,
        eventNames: {
          web_vitals: true,
          page_load: true,
          search_trips: true,
          select_ride: true,
          product_page: true,
          view_departure_times_tooltip: true,
          add_addons: true,
          checkout_addons: true,
          passenger_details: true,
          add_to_cart: true,
          book_cart: true,
          book_transfer: true,
          checkout_payment: true,
          book_failed: true,
          open_filters: true,
          click_on_header_logo: true,
          sort: true,
          login: true,
          checkout_details: true,
          server_booking_paid: true,
          add_extras: true,
          search_trips_API: true,
          view_admin_page: true,
          product_page_update: true,
          filter_by: true,
          change_date: true,
          click_sr_card_station: true,
          server_booking_payment_failed: true,
          supplier_api_reserve: true,
          server_booking_cancelled_online: true,
          click_checkout_stepper_phase: true,
          click_checkout_stepper: true,
          account_signup: true,
          help_center_popup_click: true,
          click_booking_error_cta: true,
          apply_promo_code: true,
          view_departure_times_tooltip: true,
          click_sr_card_photo: true,
          click_on_blog: true,
          double_booking_alert: true,
          search_bar_tapped: true,
          manage_my_booking_login: true,
          manage_my_booking_buttons: true,
        }
      }
    };
    biDashboardService.setActiveTab = function (index) {
      biDashboardService.activeTab = index;
    };
    return biDashboardService;
  }];
}

'use strict';

/* exported
  monitoringCreateModalService
 */

function monitoringCreateModalService($uibModal) {
  /* jshint validthis: true */
  this.show = function (queryName, biQueries) {
    $uibModal.open({
      templateUrl: 'views/monitoring/create.html',
      controller: 'MonitoringCtrl',
      resolve: {
        editedQuery: ['Restangular', function (Restangular) {
          if (queryName) {
            return Restangular.one(`monitoring/query/${queryName}`).get().then(function(biQuery) {
              return biQuery;
            });
          } else {
            return null;
          }
        }],
        biQueries: [function () {
          return biQueries;
        }],
        fetchQueries: [function () {
          return null;
        }],
        slackChannels: [function () {
          return null;
        }],
      },
      backdrop: true,
      animation: false,
    }).result.then(function() {}, function (reason) {
      console.log('Error: ', reason);
    });
  };
}

'use strict';

/*
exported
slackChannelsSelectorDirective
 */

function slackChannelsSelectorDirective(Restangular) {
  return {
    restrict: 'AE',
    require: 'ngModel',
    templateUrl: 'views/monitoring/channels-selector.directive.html',
    scope: {
      ngModel: '=ngModel',
      showLabel: '=showLabel',
      label: '@',
      showOnlySuppliers: '@',
    },
    link: function ($scope, iElement, iAttrs, ngModelCtrl) {
      $scope.selected = {};
      Restangular.all('monitoring/slack-channels').getList().then(function(slackChannels) {
        $scope.slackChannels = slackChannels;
        if ($scope.ngModel) {
          const channel = slackChannels.find(c => c.id === $scope.ngModel);
          if (channel) {
            $scope.selected = channel;
            ngModelCtrl.$setViewValue($scope.ngModel);
            ngModelCtrl.$render();
          }
        }
      });

      $scope.$watch('selected', function (val) {
        if (val && val.id) {
          ngModelCtrl.$setViewValue(val.id);
        }
      });

      $scope.updateSlackChannel = function(slackChannel) {
        ngModelCtrl.$setViewValue(slackChannel.id);
      };
    }
  };
}
